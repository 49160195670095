import React, { useEffect, useState } from 'react'
import Moment from 'moment'

import Components from '@cloudmeet/web-components'
import { Button, Radio, Tabs } from 'antd'
import { useFormik } from 'formik'
import { v4 as uuid } from 'uuid'
import Scroll from 'react-scroll'

import * as Style from './style'
import * as Yup from 'yup'
import ChatItem from './ChatItem'

import { ChatMessage } from '@cloudmeet/web-core/chat/common/models'
import { Role } from '@cloudmeet/web-core/tenants/models'

import { toTime } from '@cloudmeet/web-core/common/utils/dateUtils'
import sendMessageToAllAttendees from '@cloudmeet/web-core/chat/sendMessageToAllAttendees'
import sendMessageToAllPresenters from '@cloudmeet/web-core/chat/sendMessageToAllPresenters'
import useAuthState from '../common/hooks/useAuthState'
import useSharedPersistedState from '../common/hooks/useSharedPersistedState'
import { SharedStateKeys } from '../common/hooks/useSharedState'
import useCommandRequestV2 from '../common/hooks/useCommandRequestV2'

const scroller = Scroll.scroller
const { Element } = Scroll
const { TabPane } = Tabs
const { Input } = Components.UI
const { useDidMountEffect } = Components.Hooks

const presenterModeratorTab = 'presenter'
const attendeeTab = 'attendee'

type ChatRoomProps = {
  p2aMessages: any[]
  p2pMessages: any[]
  showAddMessageToNotes: boolean
  onChatMessageTabChanged?: (item: string) => void
}

export default ({ p2aMessages, p2pMessages, showAddMessageToNotes, onChatMessageTabChanged }: ChatRoomProps) => {
  /** ------------------------------------ **
   States
   ** ------------------------------------ **/
  const [auth] = useAuthState()
  const [selectedChatSection, setSelectedChatSection] = useState('attendee')
  const [initialValues] = useState({
    message: '',
  })
  const [, setLastP2aIdViewed] = useSharedPersistedState<string | null>(SharedStateKeys.lastP2aIdViewed, null)
  const [lastP2pIdViewed, setLastP2pIdViewed] = useSharedPersistedState<string | null>(
    SharedStateKeys.lastP2pIdViewed,
    null,
  )
  const [showP2pBadge, setShowP2pBadge] = useState<boolean>(false)
  const [showP2aBadge, setShowP2aBadge] = useState<boolean>(false)
  const [sendCommandRequest, isLoading] = useCommandRequestV2()
  /** ------------------------------------ **
   Effects
   ** ------------------------------------ **/
  useDidMountEffect(() => {
    onScroll('p2a')
  }, [p2aMessages])

  useDidMountEffect(() => {
    onScroll('p2p')
  }, [p2pMessages])

  useEffect(() => {
    setLastP2aIdViewed(getLastP2aMessageId())
  }, [])

  useDidMountEffect(() => {
    const hasUnreadMessage = p2pMessages.length > 0 && lastP2pIdViewed !== getLastP2pMessageId()
    setShowP2pBadge(selectedChatSection !== presenterModeratorTab && hasUnreadMessage)
  }, [p2pMessages])

  useDidMountEffect(() => {
    const hasUnreadMessage = p2pMessages.length > 0 && lastP2pIdViewed !== getLastP2aMessageId()
    setShowP2aBadge(selectedChatSection !== attendeeTab && hasUnreadMessage)
  }, [p2aMessages])

  const getLastP2aMessageId = (): string | null => {
    return p2aMessages.length > 0 ? p2aMessages[p2aMessages.length - 1].id : null
  }

  const getLastP2pMessageId = (): string | null => {
    return p2pMessages.length > 0 ? p2pMessages[p2pMessages.length - 1].id : null
  }
  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const onSubmit = async (values: ChatForm) => {
    const id = uuid()
    let chatName = 'p2p'

    const message: ChatMessage = {
      id,
      message: values.message,
      role: auth.role,
      username: auth.participantName,
      userId: auth.participantKey,
      roleCustomLabel: auth.roleCustomLabel,
    }

    if (selectedChatSection === attendeeTab) {
      chatName = 'p2a'
      await sendCommandRequest(() => sendMessageToAllAttendees(auth.roomId, message))
      setLastP2aIdViewed(id)
    } else {
      await sendCommandRequest(() => sendMessageToAllPresenters(auth.roomId, message))
      setLastP2pIdViewed(id)
    }

    await form.setFieldValue('message', null)

    onScroll(chatName)
  }

  const onChatSectionChange = (item: string) => {
    setSelectedChatSection(item)
    if (item === attendeeTab) {
      setLastP2aIdViewed(getLastP2aMessageId())
      setShowP2aBadge(false)
    } else if (item === presenterModeratorTab) {
      setLastP2pIdViewed(getLastP2pMessageId())
      setShowP2pBadge(false)
    }
    if (onChatMessageTabChanged) {
      onChatMessageTabChanged(item)
    }
  }

  const onScroll = (targetName: string) => {
    scroller.scrollTo(`scrollTarget_${targetName}`, {
      duration: 500,
      delay: 0,
      smooth: true,
      containerId: `chatScrollContainer_${targetName}`,
    })
  }

  const form = useFormik<ChatForm>({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({}),
  })

  /** ------------------------------------ **
   Main Component
   ** ------------------------------------ **/
  return (
    <div className={Style.container}>
      <div className={Style.chatRoomContainer}>
        <>
          {auth.role === Role.Attendee ? (
            <Tabs className={Style.tabs} activeKey={selectedChatSection} renderTabBar={() => <></>}>
              <TabPane tab={attendeeTab} key={attendeeTab} id="chatPane">
                {chatMessages('p2a', p2aMessages, auth, showAddMessageToNotes)}
              </TabPane>
            </Tabs>
          ) : (
            <Tabs
              className={Style.tabs}
              activeKey={selectedChatSection}
              renderTabBar={() => (
                <div>
                  <Radio.Group
                    className={Style.radioGroup}
                    optionType="button"
                    buttonStyle="solid"
                    value={selectedChatSection}
                    onChange={(e: any) => onChatSectionChange(e.target.value)}
                    style={{ marginBottom: 8 }}
                  >
                    <Radio.Button type="primary" value={presenterModeratorTab}>
                      {auth.role === Role.Presenter && (
                        <span>Presenters {showP2pBadge && <span className={'badge'}></span>}</span>
                      )}
                      {auth.role === Role.Moderator && (
                        <span>Presenters {showP2pBadge && <span className={'badge'}></span>}</span>
                      )}
                    </Radio.Button>
                    <Radio.Button value={attendeeTab}>
                      Attendees {showP2aBadge && <span className={'badge'}></span>}
                    </Radio.Button>
                  </Radio.Group>
                </div>
              )}
            >
              <TabPane tab={presenterModeratorTab} key={presenterModeratorTab} id="chatPane">
                {p2pMessages.length ? chatMessages('p2p', p2pMessages, auth, showAddMessageToNotes) : null}
              </TabPane>
              <TabPane tab={attendeeTab} key={attendeeTab}>
                {p2aMessages.length ? chatMessages('p2a', p2aMessages, auth, showAddMessageToNotes) : null}
              </TabPane>
            </Tabs>
          )}
        </>

        <div className={Style.footerContainer}>
          <div className={Style.footerInput}>
            <Input
              placeholder="Start typing"
              name="message"
              preventEnterBehaviour={Boolean(form.values.message === null || form.values.message.length === 0)}
              {...form}
              disabled={isLoading}
            />
            <Button
              type="link"
              icon={<i className="ri-send-plane-line" />}
              disabled={!form.values.message || isLoading}
              onClick={(e: any) => form.handleSubmit(e)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const chatMessages = (name: string, messages: any[], auth: any, showAddMessageToNotes: boolean) => {
  return (
    <Element
      className={Style.scrollContainer}
      id={`chatScrollContainer_${name}`}
      name={`chatScrollContainer_${name}`}
      style={auth.role === Role.Attendee ? { height: 'calc(100vh - 174px)' } : {}}
    >
      {messages &&
        Boolean(messages.length) &&
        messages.map((rec: any) => {
          return (
            <ChatItem
              id={rec.id}
              key={rec.id}
              name={rec.username}
              time={rec.createdAt ? toTime(Moment(rec.createdAt.seconds * 1000)) : ''}
              message={rec.message}
              role={rec.role}
              roomId={auth.roomId}
              participantId={auth.participantKey}
              showAddMessageToNotes={showAddMessageToNotes}
              roleCustomLabel={rec.roleCustomLabel}
            />
          )
        })}
      <Element id={`scrollTarget_${name}`} name={`scrollTarget_${name}`}>
        <span />
      </Element>
    </Element>
  )
}

type ChatForm = {
  message: string
}
