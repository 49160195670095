import { css } from '@emotion/css'
import Components from '@cloudmeet/web-components'

const { Colors } = Components.Styles

export const container = css`
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-content: flex-start;
  overflow: hidden;
`

export const viewerContainer = css`
  display: flex;
  height: 100vh;
`

export const experienceArea = css`
  overflow: hidden;
  width: 100%;
  position: relative;
`

export const videoPlayerContainer = css`
  width: 100%;
  height: 100vh;
  border: none;
  margin: 0;
`

export const tabbedAreaContainer = css`
  position: relative;
`

export const tabbedArea = css`
  background: white;
  width: 350px;
  height: 100%;

  .ant-tabs-nav {
    margin-bottom: 0 !important;
  }

  .ant-tabs-nav {
    padding: 1rem 1rem 0;
  }

  .ant-tabs-content-holder {
    padding: 1rem 1rem;
    background: white;
    overflow-y: auto;
    height: calc(100vh - 64px);
  }
`

export const tabbedAreaInvisible = css`
  ${tabbedArea};
  display: none !important;
`

export const tabbedBtnSwitcher = css`
  position: absolute;
  background: white;
  color: #535c68;
  font-size: 16px;
  left: -8px;
  top: 5px;
  padding: 1px;
  box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
  border-radius: 6px;
  cursor: pointer;
  z-index: 9;
`

export const tabbedBtnSwitcherOff = css`
  ${tabbedBtnSwitcher};
  left: -16px;
`

export const videoConferenceContainer = css`
  position: relative;
`

export const debugging = css`
  width: 100%;
  height: 100px;
  background-color: forestgreen;
  color: white;
  flex: 1;
  padding: 15px;
`

export const endPresentationButton = css`
  max-width: 80px;
  bottom: 15px;
  position: absolute !important;
  right: 15px;
  transition: none;
`

export const endPresentationButtonWithStatus = css`
  ${endPresentationButton};
  bottom: 80px;
  transition: none;
`

export const askQuestionButton = css`
  max-width: 140px;
  position: absolute !important;
  top: 15px;
  left: 15px;
  padding: 15px !important;
  & i {
    margin-right: 5px !important;
  }
`

export const recordingIndicator = css`
  max-width: 110px;
  position: absolute !important;
  top: 21px;
  left: 15px;
  right: 15px;
  border-radius: 32px;
  padding: 2px 5px !important;
  color: white !important;
  background: #d62c34;
  display: flex;
  justify-content: center;
  align-items: center;
  & i {
    margin-right: 5px;
    font-size: 16px;
  }
  margin: auto;
`

export const askQuestionForm = css`
  position: absolute !important;
  width: 500px;
`

// @ts-ignore
export const askQuestionStatusBarContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute !important;
  width: 100%;
  height: auto;
  bottom: 0;
`

export const askQuestionStatusBarText = css`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
`

// @ts-ignore
export const askQuestionVerifyingStatus = css`
  ${askQuestionStatusBarContainer};
  background-color: ${Colors.PrimaryColor};
`
// @ts-ignore
export const askQuestionAcceptedStatus = css`
  ${askQuestionStatusBarContainer};
  background-color: #389e0d;
`

export const modalContent = css`
  & .ant-modal {
    max-width: 720px !important;
    width: 720px !important;
  }

  & .ant-modal-body {
    width: 100%;
  }
`

export const footerActionBtn = css`
  width: auto !important;
  display: inline !important;
  margin-left: 10px !important;
`

export const modalFooterDeviceStatusInfo = css`
  background-color: #ffffff;
  border: 0;
  text-align: center;
`
