import { css } from '@emotion/css'

export const modalWrapper = css`
  & .ant-modal {
    max-width: 720px !important;
    width: 720px !important;
  }

  & .ant-modal-body {
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 600px;
  }
`

export const modalFooter = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & .ant-btn {
    margin-right: 0.5rem !important;
    width: auto !important;
    height: auto !important;
  }
`

export const modalFooterBtnContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
