import { css } from '@emotion/css'

export const container = css``

export const tabbedAreaContainer = css`
  position: relative;
`

export const tabbedArea = css`
  background: white;
  width: 450px;
  height: 100%;

  .ant-tabs-nav {
    margin-bottom: 0 !important;
    background: #f7f8fa;
  }

  .ant-tabs-nav {
    padding: 1rem 1rem 0;
  }

  .ant-tabs-content-holder {
    padding: 1rem 1rem;
    background: white;
    overflow-y: auto;
    height: calc(100vh - 64px);
  }

  .ant-tabs-tab-btn {
    position: relative;

    & .badge {
      position: absolute;
      background: red;
      width: 10px;
      height: 10px;
      top: -5px;
      right: -5px;
      border-radius: 50%;
    }
  }
`

export const tabbedAreaInvisible = css`
  ${tabbedArea};
  display: none !important;
`

export const tabbedBtnSwitcher = css`
  position: absolute;
  background: white;
  color: #535c68;
  font-size: 16px;
  left: -8px;
  top: calc(50% - 30px);
  padding: 1px;
  box-shadow: 0px 1px 2px rgba(8, 24, 35, 0.06), 0px 1px 3px rgba(8, 24, 35, 0.1);
  border-radius: 6px;
  cursor: pointer;
  z-index: 9;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const tabbedBtnSwitcherOff = css`
  ${tabbedBtnSwitcher};
  width: 22px;
  left: -22px;
`

export const chatNotification = css`
  position: absolute !important;
  top: calc(50% + 10px);
  right: 1px;
  z-index: 9999;
  height: 22px;
`
