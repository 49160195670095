import React from 'react'
import Components from '@cloudmeet/web-components'
import PresenterBadgedAvatar from './PresenterBadgedAvatar'
import * as Style from './style'
import { Role } from '@cloudmeet/web-core/tenants/models'
import { BreakoutRoomsWithParticipantsResponse } from '@cloudmeet/web-core/rooms/breakoutRooms/getBreakoutRoomsSession'
import useAuthState from '../../common/hooks/useAuthState'
import { RoomParticipantPermission } from '@cloudmeet/web-core/rooms/models'

type ComponentPros = {
  breakoutRooms: BreakoutRoomsWithParticipantsResponse[]
  onJoinBreakoutRoom: (roomId: string) => void
  onStopBreakoutSession?: () => void
  breakoutSessionLength?: number
  breakoutRoomsSessionEndsAt?: string
  isCloseBreakoutRoomsCommandLoading?: boolean
  isJoinBreakoutRoomCommandLoading?: boolean
}

const { Button, Avatar, PopConfirm, Timer } = Components.UI

const BREAKOUT_ROOM_CARD_MAX_AVATAR_SHOWING = 9

export default (props: ComponentPros) => {
  let { breakoutRooms } = props

  const [auth] = useAuthState()

  const onStopBreakoutSession = () => {
    if (props.onStopBreakoutSession) {
      props.onStopBreakoutSession()
    }
  }

  const onJoinBreakoutRoom = (roomId: string) => {
    if (props.onJoinBreakoutRoom) {
      props.onJoinBreakoutRoom(roomId)
    }
  }

  return (
    <div>
      <div className={Style.wrapper}>
        <div className={'breakout-actions'}>
          <div className={'breakout-action'}>
            <div className={'breakout-action-title'}>
              <i className="ri-grid-line mr-4"></i>
              {props.breakoutRoomsSessionEndsAt && (
                <span>
                  Breakout session | <Timer date={props.breakoutRoomsSessionEndsAt} type={'Countdown'} />
                </span>
              )}
            </div>
          </div>
          <div>
            {auth.canCloseBreakoutRooms && (
              <PopConfirm title={'Stop breakout?'} onConfirm={onStopBreakoutSession}>
                <Button
                  disabled={props.isCloseBreakoutRoomsCommandLoading}
                  loading={props.isCloseBreakoutRoomsCommandLoading}
                  label={'Stop breakout'}
                  type="danger"
                  onClick={() => {}}
                />
              </PopConfirm>
            )}
          </div>
        </div>
        <div className={'breakout-rooms'}>
          {breakoutRooms.map((breakoutRoom) => {
            let roomId = breakoutRoom.roomId
            let participants = breakoutRoom.participants.filter((p) => p.participantKey !== auth.participantKey)
            return (
              <div className={'breakout-room'}>
                <div className={'title'}>
                  <div className={'title-wrap'}>
                    <div className={'title'}>{breakoutRoom.name}</div>
                    <div className={'total-participants'}>{participants.length} Participants</div>
                  </div>
                  <div>
                    <Button
                      disabled={props.isJoinBreakoutRoomCommandLoading}
                      loading={props.isJoinBreakoutRoomCommandLoading}
                      label={'Join'}
                      type="success"
                      onClick={() => onJoinBreakoutRoom(roomId)}
                    />
                  </div>
                </div>
                <div className={'content-wrap'}>
                  <div className={'participants-list'}>
                    {participants.map((participant, index) => (
                      <>
                        <div className={'participant-list-item'}>
                          <div className={'mr-8'}>
                            {index === BREAKOUT_ROOM_CARD_MAX_AVATAR_SHOWING && participant.role !== Role.Presenter && (
                              <Avatar
                                innerText={`+${participants.length - index}`}
                                size={Style.avatarDefaults.size}
                                style={Style.avatarDefaults.styleMore}
                              />
                            )}
                            {index < BREAKOUT_ROOM_CARD_MAX_AVATAR_SHOWING && participant.role === Role.Presenter && (
                              <PresenterBadgedAvatar participant={participant} />
                            )}
                            {index < BREAKOUT_ROOM_CARD_MAX_AVATAR_SHOWING && participant.role !== Role.Presenter && (
                              <Avatar
                                innerText={participant.name}
                                size={Style.avatarDefaults.size}
                                style={Style.avatarDefaults.style}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
