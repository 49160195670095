import React, { useCallback, useEffect, useRef, useState } from 'react'
import Components from '@cloudmeet/web-components'
import { Badge } from 'antd'
import * as Style from './style'

import RoomLoader from '../common/components/RoomLoader'
import * as Routes from '../common/config/routes'
import { ROUND_TABLE } from '../common/config/routes'

import VerifyAttendee from '../verifyAttendee'
import Sidebar from '../sidebar'
import useCommandRequest from '../common/hooks/useCommandRequest'
import useQueryRequest from '../common/hooks/useQueryRequest'
import { getLastQuestionForVerification } from '@cloudmeet/web-core/questions/getLastQuestionForVerification'

import {
  endPresentation,
  getDetailsForParticipant,
  GetDetailsForParticipantResponse,
  getPublisherCredentials,
  joinPresentation,
  setLivestreamStartedTime,
  setRecordingStartedTime,
} from '@cloudmeet/web-core/rooms/api'

import updateQuestion from '@cloudmeet/web-core/questions/updateQuestion'
import listenForRoomChanges from '@cloudmeet/web-core/rooms/listenForRoomChanges'
import listenForParticipantChanges from '@cloudmeet/web-core/rooms/listenForParticipantChanges'
import BreakoutRooms from '../breakoutRooms'
import getVideoUrl from '@cloudmeet/web-core/rooms/getVideoUrl'
import BreakoutRoomsControlCenter from '../breakoutRooms/viewLiveBreakoutRooms'
import {
  BreakoutRoomsWithParticipantsResponse,
  getBreakoutRoomsSession,
} from '@cloudmeet/web-core/rooms/breakoutRooms/getBreakoutRoomsSession'
import useCommandRequestV2 from '../common/hooks/useCommandRequestV2'
import useQueryRequestV2 from '../common/hooks/useQueryRequestV2'
import { closeBreakoutRoomsSession } from '@cloudmeet/web-core/rooms/breakoutRooms/closeBreakoutRoomsSession'
import { joinBreakoutRoom, JoinBreakoutRoomResponse } from '@cloudmeet/web-core/rooms/breakoutRooms/joinBreakoutRoom'
import useAuthState from '../common/hooks/useAuthState'
import useMediaState from '../common/hooks/useMediaState'
import sendRemoveParticipantBeacon from '../common/helpers/sendRemoveParticipantBeacon'

import listenToAllParticipantChanges from '@cloudmeet/web-core/rooms/listenToAllParticipantChanges'

import { addFullscreenEventListener, closeFullscreen, isFullScreen, openFullscreen } from '../common/helpers/fullscreen'
import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'
import removeBreakoutRoomCredentialsFromParticipant from '@cloudmeet/web-core/rooms/removeBreakoutRoomCredentialsFromParticipant'

const { Button, PopConfirm, Modal, Timer, PopConfirmButtonStyles } = Components.UI
const { success } = Components.Utils.Notifications

export default () => {
  /** ------------------------------------ **
     Entity states
     ** ------------------------------------ **/
  const [auth, setAuth] = useAuthState()
  const [media, setMedia] = useMediaState()
  const apiRef = useRef(null)
  const videoConferenceContainerRef = useRef(null)

  const [verifyAttendeeRoomDetails, setVerifyAttendeeRoomDetails] = useState<{
    questionId: string
    name: string
    alternativeName: string
    jwt: string
    loadingMessage?: string
  } | null>(null)

  const [jitsiRef, setJitsiRef] = useState<any | null>(null)
  const [arePermissionsUpdated, setArePermissionsUpdated] = useState<boolean>(false)
  const [areRoomDataUpdated, setAreRoomDataUpdated] = useState<boolean>(false)
  const { sendQueryRequest: sendGetDetailsForParticipant } = useQueryRequest()
  const { isLoading: isEndPresentationRequestLoading, sendCommandRequest: sendEndPresentation } = useCommandRequest()
  const {
    isLoading: isSetLivestreamStartedTimeLoading,
    sendCommandRequest: sendSetLivestreamStartedTime,
  } = useCommandRequest()
  const {
    isLoading: isSetRecordingStartedTimeLoading,
    sendCommandRequest: sendSetRecordingStartedTime,
  } = useCommandRequest()
  const {
    isLoading: isGenerateVerifyAttendeeRoomCredentials,
    sendCommandRequest: sendGenerateVerifyAttendeeRoomCredentials,
  } = useCommandRequest()
  const {
    isLoading: isAcceptAttendeeForLiveQuestionLoading,
    sendCommandRequest: sendAcceptAttendeeForLiveQuestionRequest,
  } = useCommandRequest()
  const {
    isLoading: isDeclineAttendeeForLiveQuestionLoading,
    sendCommandRequest: sendDeclineAttendeeForLiveQuestionRequest,
  } = useCommandRequest()
  const {
    isLoading: isSwitchToTextQuestionLoading,
    sendCommandRequest: sendSwitchToTextQuestionRequest,
  } = useCommandRequest()
  const [sendCloseBreakoutRoomsCommandRequest, isCloseBreakoutRoomsCommandLoading] = useCommandRequestV2()
  const [sendJoinBreakoutRoomCommandRequest, isJoinBreakoutRoomCommandLoading] = useCommandRequestV2()
  const [, , sendBreakoutRoomsQueryRequest] = useQueryRequestV2<BreakoutRoomsWithParticipantsResponse[]>()
  const [breakoutRooms, setBreakoutRooms] = useState<BreakoutRoomsWithParticipantsResponse[]>([])
  const [showBreakoutRooms, setShowBreakoutRooms] = useState(false)
  const [breakoutSessionData, setBreakoutSessionData] = useState<{
    breakoutSessionLength: number
    breakoutRoomsSessionStartedAt: string
    breakoutRoomsSessionEndsAt: string
  } | null>()
  const [breakoutRoomsListeners, setBreakoutRoomsListeners] = useState<any[]>([])
  const [breakoutRoomParticipantsChange, setBreakoutRoomParticipantsChange] = useState<
    { roomId: string; participants: any } | undefined
  >()
  const [, , sendGetRoomDetailsForParticipant] = useQueryRequestV2<GetDetailsForParticipantResponse | null>(null)
  const [totalUnreadMessages, setTotalUnreadMessages] = useState<number>(0)

  const [isFullscreenModeOn, setIsFullscreenModeOn] = useState(false)
  const [screenSharingStatusChanged, setScreenSharingStatusChanged] = useState(false)
  const [isHost, setIsHost] = useState(auth.isHost)
  const [triggerMuteAll, setTriggerMuteAll] = useSharedState(SharedStateKeys.TriggerMuteAll, false)
  /** ------------------------------------ **
     Event Handlers
     ** ------------------------------------ **/
  const onEndPresentation = async () => {
    await sendEndPresentation(() =>
      endPresentation({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        participantKey: auth.participantKey,
      }),
    )
  }

  const removeJitsi = () => {
    // @ts-ignore
    apiRef.current?.dispose()
  }

  const onVerifiedAttendee = async (credentials: any) => {
    setVerifyAttendeeRoomDetails({
      questionId: credentials.questionId,
      name: credentials.name,
      jwt: credentials.jwt,
      alternativeName: credentials.alternativeName,
    })

    removeJitsi()
  }

  const onTotalUnreadMessagesChanges = (totalMessages: number) => {
    setTotalUnreadMessages(totalMessages)
  }

  const onAcceptAttendeeForLiveQuestion = async () => {
    if (verifyAttendeeRoomDetails) {
      const result = await sendAcceptAttendeeForLiveQuestionRequest(() =>
        updateQuestion(verifyAttendeeRoomDetails.questionId, auth.roomId, {
          status: 'VERIFIED',
        }),
      )

      if (result.completed) {
        setVerifyAttendeeRoomDetails(null)
        await initJitsi(jitsiRef)
      }
    }
  }

  const onDeclineAttendeeForLiveQuestion = async () => {
    if (verifyAttendeeRoomDetails) {
      const result = await sendDeclineAttendeeForLiveQuestionRequest(() =>
        updateQuestion(verifyAttendeeRoomDetails.questionId, auth.roomId, {
          status: 'DELETED',
        }),
      )

      if (result.completed) {
        setVerifyAttendeeRoomDetails(null)
        await initJitsi(jitsiRef)
      }
    }
  }

  const onSwitchToTextQuestion = async () => {
    if (verifyAttendeeRoomDetails) {
      const result = await sendSwitchToTextQuestionRequest(() =>
        updateQuestion(verifyAttendeeRoomDetails.questionId, auth.roomId, {
          type: 'TEXT',
          status: 'CREATED',
        }),
      )

      if (result.completed) {
        setVerifyAttendeeRoomDetails(null)
        await initJitsi(jitsiRef)
      }
    }
  }

  const onStopBreakoutSession = async () => {
    await sendCloseBreakoutRoomsCommandRequest(() =>
      closeBreakoutRoomsSession({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        participantKey: auth.participantKey,
      }),
    )
  }

  const onJoinBreakoutRoom = async (breakoutRoomId: string) => {
    const result = await sendJoinBreakoutRoomCommandRequest(() =>
      joinBreakoutRoom({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        participantKey: auth.participantKey,
        breakoutRoomId: breakoutRoomId,
        participantName: auth.participantName,
      }),
    )

    if (result.completed) {
      success(`Successfully joined room.`)
      const data: JoinBreakoutRoomResponse = result.data as JoinBreakoutRoomResponse
      window.location.href = getVideoUrl(data.roomId, data.roomKey, data.participantKey, ROUND_TABLE)
    }
  }

  const onLeaveMeeting = async () => {
    if (auth.parentRoom) {
      await removeBreakoutRoomCredentialsFromParticipant(auth.roomId, auth.roomKey, auth.participantKey)

      window.location.href = getVideoUrl(
        auth.parentRoom.roomId,
        auth.parentRoom.roomKey,
        auth.participantKey,
        ROUND_TABLE,
      )
      return
    }

    window.location.href = Routes.MEETING_LEFT_URL
  }

  const onCloseAllBreakoutRooms = async () => {
    await sendCloseBreakoutRoomsCommandRequest(() =>
      closeBreakoutRoomsSession({
        roomId: auth.parentRoom?.roomId ?? '',
        roomKey: auth.parentRoom?.roomKey ?? '',
        participantKey: auth.participantKey,
      }),
    )
  }

  const getJitsiMeetInstance = (): any => {
    return apiRef.current
  }

  /** ------------------------------------ **
     Effects
     ** ------------------------------------ **/
  const updateAudioVideoSettings = async () => {
    const jitsiMeet = getJitsiMeetInstance()

    const devices = await jitsiMeet.getCurrentDevices()
    const audioMuted = await jitsiMeet.isAudioMuted()
    const videoMuted = await jitsiMeet.isVideoMuted()

    setMedia({
      ...media,
      videoDevice: devices?.videoInput?.label,
      audioInputDevice: devices?.audioInput?.label,
      audioOutputDevice: devices?.audioOutput?.label,
      audioEnabled: !audioMuted,
      videoEnabled: !videoMuted,
    })
  }

  const refreshAuth = async () => {
    const result = await sendGetRoomDetailsForParticipant(() =>
      getDetailsForParticipant({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        participantKey: auth.participantKey,
      }),
    )

    if (result) {
      setAuth({
        ...result,
        participantName: auth.participantName,
      })
    }
  }

  const startLiveStream = async (jitsiMeet: any) => {
    if (!auth.canStartLivestream) return

    const credentials = await getPublisherCredentials({
      roomId: auth.roomId,
      roomKey: auth.roomKey,
      participantKey: auth.participantKey,
    })

    jitsiMeet.executeCommand('startRecording', {
      mode: 'stream',
      rtmpStreamKey: `rtmp://live-rtmp-pub.millicast.com:1935/v2/pub/${auth.roomId}?token=${credentials.publishingToken}`,
    })
  }

  const startRecording = (jitsiMeet: any) => {
    if (auth.canRecordOnVideoConference) {
      jitsiMeet.executeCommand('startRecording', {
        mode: 'file',
      })
    }
  }

  const initJitsi = async (parentNode: any) => {
    if (!parentNode) throw new Error('Parent node is not defined')

    // @ts-ignore
    const jitsiMeet = new JitsiMeetExternalAPI('8x8.vc', {
      roomName: auth.videoConferenceRoomId,
      parentNode: parentNode,
      jwt: auth.videoConferenceJwt,
      configOverwrite: {
        prejoinPageEnabled: false,
        hideConferenceSubject: true,
        hideRecordingLabel: true,
        hideConferenceTimer: true,
        hideParticipantsStats: true,
        disableLocalVideoFlip: true,
        doNotFlipLocalVideo: true,
        toolbarButtons: [
          'microphone',
          'camera',
          'desktop',
          'fullscreen',
          'select-background',
          'filmstrip',
          'tileview',
          'toggle-camera',
          'settings',
        ],
        startWithAudioMuted: !media.audioEnabled,
        startWithVideoMuted: !media.videoEnabled,
        remoteVideoMenu: {
          disableKick: true,
          disableGrantModerator: true,
        },
        readOnlyName: true,
        buttonsWithNotifyClick: ['fullscreen'],
      },
      interfaceConfigOverwrite: {
        DISABLE_VIDEO_BACKGROUND: true,
        VIDEO_QUALITY_LABEL_DISABLED: true,
      },
      devices: {
        audioInput: media.audioInputDevice,
        audioOutput: media.audioOutputDevice,
        videoInput: media.videoDevice,
      },
    })

    jitsiMeet.on('audioAvailabilityChanged', async () => {
      await updateAudioVideoSettings()
    })

    jitsiMeet.on('videoAvailabilityChanged', async () => {
      await updateAudioVideoSettings()
    })

    jitsiMeet.on('videoConferenceJoined', async (data: any) => {
      await joinPresentation({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        conferenceId: data.id,
        participantName: auth.participantName,
        participantKey: auth.participantKey,
      })

      await startLiveStream(jitsiMeet)

      startRecording(jitsiMeet)
    })

    jitsiMeet.on('toolbarButtonClicked', (data: { key: string }) => {
      if (data.key === 'fullscreen') {
        if (isFullScreen()) {
          closeFullscreen()
            .then(() => {})
            .catch((err) => {
              console.error(err)
            })
        } else {
          openFullscreen(videoConferenceContainerRef.current)
            .then(() => {})
            .catch((err) => {
              console.error(err)
            })
        }
      }
    })

    jitsiMeet.on('screenSharingStatusChanged', (data: { on: boolean }) => {
      setScreenSharingStatusChanged(data.on)
    })

    jitsiMeet.addListener('recordingStatusChanged', async (data: any) => {
      if (data.mode === 'stream' && data.on && auth.canStartLivestream) {
        await sendSetLivestreamStartedTime(() =>
          setLivestreamStartedTime({
            roomId: auth.roomId,
            roomKey: auth.roomKey,
            participantKey: auth.participantKey,
          }),
        )
      }

      if (data.mode === 'file' && data.on && auth.canRecordOnVideoConference) {
        await sendSetRecordingStartedTime(() =>
          setRecordingStartedTime({
            roomId: auth.roomId,
            roomKey: auth.roomKey,
            participantKey: auth.participantKey,
          }),
        )
      }
    })

    apiRef.current = jitsiMeet
  }

  const fillBreakoutRooms = async () => {
    const breakoutRoomsList =
      (await sendBreakoutRoomsQueryRequest(() =>
        getBreakoutRoomsSession({
          roomId: auth.roomId,
          roomKey: auth.roomKey,
          participantKey: auth.participantKey,
        }),
      )) ?? []

    const listeners: any[] = []
    breakoutRoomsList.forEach((x) => {
      const unsubscribe = listenToAllParticipantChanges(x.roomId, (data: any[]) => {
        setBreakoutRoomParticipantsChange({
          roomId: x.roomId,
          participants: data,
        })
      })
      listeners.push(unsubscribe)
    })

    setBreakoutRoomsListeners(listeners)
    setBreakoutRooms(breakoutRoomsList)
  }

  useEffect(() => {
    ;(async () => {
      const unsubscribeToAllParticipantChanges = listenToAllParticipantChanges(auth.roomId, async (data: any) => {
        if (auth.canListBreakoutRooms) {
          const breakoutRoomsList =
            (await sendBreakoutRoomsQueryRequest(() =>
              getBreakoutRoomsSession({
                roomId: auth.roomId,
                roomKey: auth.roomKey,
                participantKey: auth.participantKey,
              }),
            )) ?? []
          setBreakoutRooms(breakoutRoomsList)
        }
      })

      return () => {
        unsubscribeToAllParticipantChanges()
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      await joinPresentation({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        conferenceId: '',
        participantName: auth.participantName,
        participantKey: auth.participantKey,
      })
      const unsubscribeListenForRoomChanges = listenForRoomChanges(auth.roomId, async (data: any) => {
        if (data.presentationEnded) {
          window.location.href = auth.parentRoom
            ? getVideoUrl(auth.parentRoom.roomId, auth.parentRoom.roomKey, auth.participantKey, ROUND_TABLE)
            : Routes.PRESENTATION_ENDED
          return
        }

        setAuth({
          ...auth,
          presentationStarted: data.presentationStarted,
          noteTimerStartsAt: data.noteTimerStartsAt,
        })
        setAreRoomDataUpdated(true)

        if (data.breakoutRoomsSessionStartedAt) {
          if (auth.canListBreakoutRooms) {
            await fillBreakoutRooms()
          }

          setBreakoutSessionData({
            breakoutRoomsSessionEndsAt: data.breakoutRoomsSessionEndsAt,
            breakoutSessionLength: data.breakoutSessionLength,
            breakoutRoomsSessionStartedAt: data.breakoutRoomsSessionStartedAt,
          })
        } else {
          setBreakoutSessionData(null)
        }
      })

      const unsubscribeListenForParticipantChanges = listenForParticipantChanges(
        auth.roomId,
        auth.participantKey,
        async (data: any) => {
          if (data.kickedOut) {
            window.location.href = Routes.NOT_AUTHORIZED_URL
            return
          }

          if (data.breakoutRoomCredentials) {
            window.location.href = getVideoUrl(
              data.breakoutRoomCredentials.roomId,
              data.breakoutRoomCredentials.roomKey,
              data.breakoutRoomCredentials.participantKey,
              ROUND_TABLE,
            )
            return
          }

          if (data.returnToMainRoom && auth.parentRoom) {
            window.location.href = getVideoUrl(
              auth.parentRoom.roomId,
              auth.parentRoom.roomKey,
              auth.participantKey,
              ROUND_TABLE,
            )
            return
          }

          setIsHost(data.isHost)

          setArePermissionsUpdated(true)
        },
      )

      if (auth.canManageQuestions) {
        const lastQuestionForVerification = await getLastQuestionForVerification(auth.roomId)
        if (lastQuestionForVerification && lastQuestionForVerification.roomCredentials) {
          removeJitsi()

          setVerifyAttendeeRoomDetails({
            questionId: lastQuestionForVerification.docId,
            name: lastQuestionForVerification.roomCredentials?.roomSubject,
            jwt: lastQuestionForVerification.roomCredentials?.jwt,
            alternativeName: lastQuestionForVerification.roomCredentials?.roomName,
          })
        }
      }

      return () => {
        unsubscribeListenForRoomChanges()
        unsubscribeListenForParticipantChanges()
      }
    })()
  }, [])

  useEffect(() => {
    window.addEventListener(
      'beforeunload',
      (event) => {
        sendRemoveParticipantBeacon({
          roomId: auth.roomId,
          roomKey: auth.roomKey,
          participantKey: auth.participantKey,
        })
      },
      false,
    )
  }, [])

  //We use the callback in order to initialize Jitsi only when the node is available
  const jitsiRefCallBack = useCallback((node: any) => {
    if (node !== null) {
      ;(async () => {
        await initJitsi(node)
        setJitsiRef(node)
      })()
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      const breakoutRoomsSessionEnded = !breakoutSessionData && breakoutRooms.length > 0

      if (breakoutRoomsSessionEnded && auth.canCloseBreakoutRooms) {
        breakoutRoomsListeners.forEach((unsubscribe) => {
          unsubscribe()
        })
      }

      if (breakoutRoomsSessionEnded) {
        setBreakoutRooms([])
        setShowBreakoutRooms(false)
      }
    })()
  }, [breakoutSessionData])

  useEffect(() => {
    if (!breakoutRoomParticipantsChange || breakoutRooms.length === 0) return

    const breakoutRoom = breakoutRooms.find((br) => br.roomId === breakoutRoomParticipantsChange.roomId)
    if (breakoutRoom) {
      breakoutRoom.participants = [...breakoutRoomParticipantsChange.participants]
    }

    setBreakoutRooms([...breakoutRooms])
  }, [breakoutRoomParticipantsChange])

  useEffect(() => {
    addFullscreenEventListener(() => {
      setIsFullscreenModeOn(isFullScreen())
    })
  }, [])

  useEffect(() => {
    //We save the value of media.videoEnabled in a variable because it will be changed by videoAvailabilityChanged event
    const videoEnabled = media.videoEnabled
    if (screenSharingStatusChanged) {
      const timeout = setTimeout(() => {
        if (videoEnabled) {
          getJitsiMeetInstance().executeCommand('toggleVideo')
        }
        clearTimeout(timeout)
      }, 1500)
    }
  }, [screenSharingStatusChanged])

  useEffect(() => {
    ;(async () => {
      if (isHost !== auth.isHost) {
        await refreshAuth()
      }
    })()
  }, [isHost])

  useEffect(() => {
    if (triggerMuteAll) {
      getJitsiMeetInstance().executeCommand('muteEveryone', 'audio')
      setTriggerMuteAll(false)
    }
  }, [triggerMuteAll])

  /** ------------------------------------ **
     Main Component
     ** ------------------------------------ **/
  return (
    <div className={Style.container}>
      {breakoutRooms && breakoutRooms.length > 0 && (
        <>
          <div className={Style.viewerContainer}>
            <div className={Style.experienceArea}>
              <div className={Style.videoConferenceContainer}>
                <BreakoutRoomsControlCenter
                  breakoutRooms={breakoutRooms ?? []}
                  onJoinBreakoutRoom={onJoinBreakoutRoom}
                  onStopBreakoutSession={onStopBreakoutSession}
                  breakoutRoomsSessionEndsAt={breakoutSessionData?.breakoutRoomsSessionEndsAt}
                  breakoutSessionLength={breakoutSessionData?.breakoutSessionLength}
                  isCloseBreakoutRoomsCommandLoading={isCloseBreakoutRoomsCommandLoading}
                  isJoinBreakoutRoomCommandLoading={isJoinBreakoutRoomCommandLoading}
                />
              </div>
            </div>

            <Sidebar onVerifiedAttendee={onVerifiedAttendee} onTotalUnreadMessagesChanges={(totalMessages) => {}} />
          </div>
        </>
      )}

      {(!breakoutRooms || breakoutRooms.length === 0) && (
        <div className={Style.container}>
          {areRoomDataUpdated && auth.presentationStarted && (
            <>
              <div className={Style.viewerContainer}>
                {/* Experience */}
                <div className={Style.experienceArea}>
                  <div className={Style.videoConferenceContainer} ref={videoConferenceContainerRef}>
                    <div className={Style.roomNameWrap}>
                      <span className={'room-name'}>{auth.roomName}</span>
                      {isFullscreenModeOn && <Badge className={Style.chatNotification} count={totalUnreadMessages} />}

                      {breakoutSessionData && (
                        <span className={'breakout-room-countdown'}>
                          <Timer date={breakoutSessionData.breakoutRoomsSessionEndsAt} type={'Countdown'} />
                        </span>
                      )}

                      {auth.requiresRecording && auth.hasStartLivestream && auth.noteTimerStartsAt && (
                        <span className={Style.recordingIndicatorWrap}>
                          <div className={Style.recordingIndicator}>
                            <i className="ri-record-circle-line" />
                            Recording
                          </div>
                        </span>
                      )}
                    </div>

                    {breakoutSessionData && !auth.isBreakoutRoom && (
                      <div className={'breakout-room-session-running-wrap'}>
                        <h1>Breakout Session is running</h1>
                        <h2> Please wait...</h2>
                        <h3>
                          You will join in{' '}
                          <Timer date={breakoutSessionData.breakoutRoomsSessionEndsAt} type={'Countdown'} />
                        </h3>
                      </div>
                    )}

                    <div className={Style.iframeContainer} ref={jitsiRefCallBack} />

                    <div>
                      {arePermissionsUpdated && auth.canEndPresentation && !isFullscreenModeOn && (
                        <>
                          <PopConfirm title={'End meeting?'} onConfirm={() => onEndPresentation()}>
                            <Button
                              className={Style.endPresentationButton}
                              loading={isEndPresentationRequestLoading}
                              disabled={isEndPresentationRequestLoading}
                              label={'End meeting'}
                              type="danger"
                              onClick={() => {}}
                            />
                          </PopConfirm>
                        </>
                      )}

                      {arePermissionsUpdated && !isFullscreenModeOn && (
                        <div className={'right-buttons-wrap'}>
                          {auth.isHostForParentRoom && (
                            <PopConfirm
                              title={'Close all breakout rooms and return to main room?'}
                              okButtonClassName={PopConfirmButtonStyles.Red}
                              onConfirm={() => onCloseAllBreakoutRooms()}
                            >
                              <Button
                                label={'Close all breakout rooms'}
                                type="dangerDark"
                                onClick={() => {}}
                                loading={isCloseBreakoutRoomsCommandLoading}
                                disabled={isCloseBreakoutRoomsCommandLoading}
                              />
                            </PopConfirm>
                          )}

                          {!auth.canEndPresentation && (
                            <PopConfirm title={'Leave meeting?'} onConfirm={() => onLeaveMeeting()}>
                              <Button label={'Leave meeting'} type="danger" onClick={() => {}} />
                            </PopConfirm>
                          )}
                        </div>
                      )}

                      {arePermissionsUpdated && auth.canStartBreakoutRooms && !isFullscreenModeOn && (
                        <Button
                          className={Style.startBreakoutRooms}
                          label={''}
                          icon={<i className="ri-apps-2-line"></i>}
                          type="normal"
                          onClick={() => {
                            setShowBreakoutRooms(true)
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <Sidebar
                  onVerifiedAttendee={onVerifiedAttendee}
                  onTotalUnreadMessagesChanges={onTotalUnreadMessagesChanges}
                />
              </div>

              {showBreakoutRooms && (
                <BreakoutRooms
                  onCanceled={() => {
                    setShowBreakoutRooms(false)
                  }}
                />
              )}

              <Modal
                wrapClassName={Style.modalContent}
                style={{ top: 20 }}
                title={verifyAttendeeRoomDetails?.name}
                visible={!!verifyAttendeeRoomDetails}
                footer={
                  <div className={Style.verifyModalFooter}>
                    <div style={{ width: '5%' }} />
                    <div className={Style.verifyModalFooterBtnContainer}>
                      <Button
                        key="submit"
                        type="success"
                        label={'Accept'}
                        loading={isAcceptAttendeeForLiveQuestionLoading}
                        disabled={isAcceptAttendeeForLiveQuestionLoading}
                        onClick={() => onAcceptAttendeeForLiveQuestion()}
                      />
                      <Button
                        key="submit"
                        type="normal"
                        label={'Switch to text question'}
                        loading={isSwitchToTextQuestionLoading}
                        disabled={isSwitchToTextQuestionLoading}
                        onClick={() => onSwitchToTextQuestion()}
                      />
                      <Button
                        key="decline"
                        type="danger"
                        label={'Decline'}
                        loading={isDeclineAttendeeForLiveQuestionLoading}
                        disabled={isDeclineAttendeeForLiveQuestionLoading}
                        onClick={() => onDeclineAttendeeForLiveQuestion()}
                      />
                    </div>
                  </div>
                }
              >
                {verifyAttendeeRoomDetails && (
                  <VerifyAttendee
                    role={auth.role}
                    roomSubject={verifyAttendeeRoomDetails.name}
                    roomName={verifyAttendeeRoomDetails.alternativeName}
                    jwt={verifyAttendeeRoomDetails.jwt}
                    key={verifyAttendeeRoomDetails.alternativeName}
                    displayName={auth.participantName}
                  />
                )}
              </Modal>
            </>
          )}

          {!areRoomDataUpdated && auth.canStartPresentation && <RoomLoader title={'Starting...'} />}

          {areRoomDataUpdated && !auth.presentationStarted && <RoomLoader title={'Presentation has not started yet'} />}
        </div>
      )}
    </div>
  )
}
