import React, { useEffect, useState } from 'react'
import { Badge } from 'antd'
import Components from '@cloudmeet/web-components'

import * as Style from './style'
import AttendeeNotes from '../notes'
import ChatRoom from '../chat/ChatRoom'
import QuestionQueue from '../questions/QuestionQueue'
import Participants from '../participants'

import { Role } from '@cloudmeet/web-core/tenants/models'
import listenToP2AMessages from '@cloudmeet/web-core/chat/listenToP2AMessages'
import listenToP2PMessages from '@cloudmeet/web-core/chat/listenToP2PMessages'
import listenToAllQuestionChanges from '@cloudmeet/web-core/questions/listenToAllQuestionChanges'
import listenToAllParticipantChanges from '@cloudmeet/web-core/rooms/listenToAllParticipantChanges'
import useAuthState from '../common/hooks/useAuthState'

import useSharedPersistedState from '../common/hooks/useSharedPersistedState'
import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'
import { addFullscreenEventListener, isFullScreen } from '../common/helpers/fullscreen'

const { Tab } = Components.UI
const { useDidMountEffect } = Components.Hooks

const questionsTabKey = 'questionsTab'
const chatTabKey = 'chatTab'

export type SidebarProps = {
  onVerifiedAttendee?: (credentials: any) => void
  onTotalUnreadMessagesChanges?: (totalMessages: number) => void
}

export default (props: SidebarProps) => {
  const { onVerifiedAttendee, onTotalUnreadMessagesChanges } = props

  const [auth] = useAuthState()

  const [questions, setQuestions] = useState([])
  const visibleQuestions: any[] = questions ? questions.filter((q: any) => q.status !== 'DELETED') : []
  const [lastQuestionIdViewed, setLastQuestionViewed] = useState<string | null>(null)

  const [p2aMessages, setP2aMessages] = useState([])
  const [p2pMessages, setP2PMessages] = useState([])
  const [lastP2aIdViewed, setLastP2aIdViewed] = useSharedPersistedState<string | null>(
    SharedStateKeys.lastP2aIdViewed,
    null,
  )
  const [lastP2pIdViewed, setLastP2pIdViewed] = useSharedPersistedState<string | null>(
    SharedStateKeys.lastP2pIdViewed,
    null,
  )

  const [participants, setParticipants] = useState([])

  const [showTabbedArea, setShowTabbedArea] = useState(true)
  const [showQuestionBadge, setShowQuestionBadge] = useState(false)
  const [showChatBadge, setShowChatBadge] = useState(false)
  const [currentActiveTab, setCurrentActiveTab] = useState(auth.canAddNotes ? 'notesTab' : chatTabKey)
  const [totalUnreadMessages, setTotalUnreadMessages] = useState<Number>(0)
  const [chatRoomTabSelected, setChatRoomTabSelected] = useState<string>('attendee')
  const [isFullscreenModeOn, setIsFullscreenModeOn] = useState(false)

  const getLastQuestionId = () => {
    return visibleQuestions.length > 0 ? visibleQuestions[visibleQuestions.length - 1].docId : null
  }

  const getLastP2aMessageId = (): string | null => {
    // @ts-ignore
    return p2aMessages.length > 0 ? p2aMessages[p2aMessages.length - 1].id : null
  }

  const getLastP2pMessageId = (): string | null => {
    // @ts-ignore
    return p2pMessages.length > 0 ? p2pMessages[p2pMessages.length - 1].id : null
  }

  const calculateUnreadMessages = () => {
    const lastP2aIdViewedIndex = p2aMessages.findIndex((x: any) => x.id === lastP2aIdViewed)
    const totalP2aUnreadMessages = lastP2aIdViewedIndex !== -1 ? p2aMessages.length - 1 - lastP2aIdViewedIndex : 0

    const lastP2pIdViewedIndex = p2pMessages.findIndex((x: any) => x.id === lastP2pIdViewed)
    const totalP2pUnreadMessages = lastP2pIdViewedIndex !== -1 ? p2pMessages.length - 1 - lastP2pIdViewedIndex : 0

    return totalP2aUnreadMessages + totalP2pUnreadMessages
  }

  useEffect(() => {
    const unsubscribeToP2AMessages = listenToP2AMessages(auth.roomId, (data: any) => {
      setP2aMessages(data)
    })

    const unsubscribeToP2PMessages = listenToP2PMessages(auth.roomId, (data: any) => {
      setP2PMessages(data)
    })

    const unsubscribeToAllQuestionChanges = listenToAllQuestionChanges(auth.roomId, (data: any) => {
      setQuestions(data)
    })

    const unsubscribeToAllParticipantChanges = listenToAllParticipantChanges(auth.roomId, (data: any) => {
      setParticipants(data)
    })

    return () => {
      unsubscribeToP2AMessages()
      unsubscribeToP2PMessages()
      unsubscribeToAllQuestionChanges()
      unsubscribeToAllParticipantChanges()
    }
  }, [])

  useEffect(() => {
    addFullscreenEventListener(() => {
      setIsFullscreenModeOn(isFullScreen())
    })
  }, [])

  useEffect(() => {
    if (isFullscreenModeOn || !showTabbedArea || currentActiveTab !== chatTabKey) {
      return
    }

    if (chatRoomTabSelected === 'attendee') {
      setLastP2aIdViewed(getLastP2aMessageId())
    } else if (chatRoomTabSelected === 'presenter') {
      setLastP2pIdViewed(getLastP2pMessageId())
    }
  }, [p2aMessages, p2pMessages, showTabbedArea, isFullscreenModeOn])

  useEffect(() => {
    const currentActiveTabIsNotQuestionTab = currentActiveTab !== questionsTabKey
    const isNotSeenYetQuestion = lastQuestionIdViewed !== getLastQuestionId()

    setShowQuestionBadge(visibleQuestions.length > 0 && currentActiveTabIsNotQuestionTab && isNotSeenYetQuestion)
  }, [visibleQuestions])

  //check for new attendees messages
  useEffect(() => {
    const currentActiveTabIsNotChatTab = currentActiveTab !== chatTabKey

    const isNotSeenYetChatMessage = lastP2aIdViewed !== getLastP2aMessageId()

    const hasAnyMessage = p2aMessages.length > 0
    setShowChatBadge(hasAnyMessage && currentActiveTabIsNotChatTab && isNotSeenYetChatMessage)

    const newValue = calculateUnreadMessages()
    setTotalUnreadMessages(newValue)
    if (onTotalUnreadMessagesChanges) {
      onTotalUnreadMessagesChanges(newValue)
    }
  }, [p2aMessages])

  //check for new presenter messages
  useEffect(() => {
    const currentActiveTabIsNotChatTab = currentActiveTab !== chatTabKey

    const isNotSeenYetChatMessage = lastP2pIdViewed !== getLastP2pMessageId()

    const hasAnyMessage = auth.role !== Role.Attendee && p2pMessages.length > 0
    setShowChatBadge(hasAnyMessage && currentActiveTabIsNotChatTab && isNotSeenYetChatMessage)

    const newValue = calculateUnreadMessages()
    setTotalUnreadMessages(newValue)
    if (onTotalUnreadMessagesChanges) {
      onTotalUnreadMessagesChanges(newValue)
    }
  }, [p2pMessages])

  useEffect(() => {
    const newValue = calculateUnreadMessages()
    setTotalUnreadMessages(newValue)
    if (onTotalUnreadMessagesChanges) {
      onTotalUnreadMessagesChanges(newValue)
    }
  }, [lastP2aIdViewed, lastP2pIdViewed])

  const onSidebarTogglerClick = () => {
    setShowTabbedArea(!showTabbedArea)
  }

  const onChatRoomTabChanged = (item: string) => {
    setChatRoomTabSelected(item)
  }

  return (
    <div className={Style.tabbedAreaContainer}>
      {showTabbedArea ? (
        <SidebarToggler showTabbedArea={showTabbedArea} onClicked={() => onSidebarTogglerClick()} />
      ) : (
        <>
          <SidebarToggler showTabbedArea={showTabbedArea} onClicked={() => onSidebarTogglerClick()} />
          <Badge className={Style.chatNotification} count={totalUnreadMessages} />
        </>
      )}
      <div
        className={showTabbedArea ? Style.tabbedArea : Style.tabbedAreaInvisible}
        style={auth.canManageQuestions ? { width: 450 } : {}}
      >
        <Tab
          active={currentActiveTab}
          tabs={[
            {
              component: (
                <AttendeeNotes
                  participantKey={auth.participantKey}
                  roomId={auth.roomId}
                  roomKey={auth.roomKey}
                  noteTimerStartsAt={auth.noteTimerStartsAt}
                  showAddNotesForm={true}
                />
              ),
              key: 'notesTab',
              title: 'Notes',
              hidden: !auth.canAddNotes,
            },
            {
              component: (
                <ChatRoom
                  showAddMessageToNotes={auth.canAddNotes}
                  p2aMessages={p2aMessages}
                  p2pMessages={p2pMessages}
                  onChatMessageTabChanged={(item: string) => onChatRoomTabChanged(item)}
                />
              ),
              key: chatTabKey,
              title: (
                <>
                  <span>Chat</span>
                  {showChatBadge && <span className={'badge'}></span>}
                </>
              ),
            },
            {
              component: <QuestionQueue questions={questions ?? []} onVerifiedAttendee={onVerifiedAttendee} />,
              key: questionsTabKey,
              title: (
                <>
                  <span>Questions</span>
                  {showQuestionBadge && <span className={'badge'}></span>}
                </>
              ),
              hidden: !auth.canManageQuestions,
            },
            {
              component: <Participants participants={participants} />,
              key: 'participantsTab',
              title: (
                <>
                  <span>{`Attendees(${participants ? participants?.length : 0})`}</span>
                </>
              ),
            },
          ]}
          onSelect={(activeKey: string) => {
            setCurrentActiveTab(activeKey)

            if (activeKey === questionsTabKey) {
              setShowQuestionBadge(false)
              setLastQuestionViewed(getLastQuestionId())
            } else if (activeKey === chatTabKey) {
              setShowChatBadge(false)
              if (auth.role === Role.Attendee) {
                setLastP2aIdViewed(getLastP2aMessageId())
              }
            }
          }}
        />
      </div>
    </div>
  )
}

const SidebarToggler = ({ showTabbedArea, onClicked }: { showTabbedArea: boolean; onClicked: () => void }) => {
  return (
    <a
      className={showTabbedArea ? Style.tabbedBtnSwitcher : Style.tabbedBtnSwitcherOff}
      onClick={() => {
        onClicked()
      }}
    >
      {showTabbedArea ? <i className="ri-arrow-right-s-line" /> : <i className="ri-arrow-left-s-line" />}
    </a>
  )
}
