import React from 'react'
import Components from '@cloudmeet/web-components'
import { Button } from 'antd'

import * as Style from './style'
import QuestionQueueItem, { QuestionQueueItemDto } from './QuestionQueueItem'

import { generateTemporaryAttendeeRoomCredentials } from '@cloudmeet/web-core/rooms/api'
import updateQuestion from '@cloudmeet/web-core/questions/updateQuestion'
import cancelAllQuestions from '@cloudmeet/web-core/questions/cancelAllQuestions'
import useAuthState from '../common/hooks/useAuthState'

const { PopConfirm } = Components.UI

export type QuestionQueueProps = {
  onVerifiedAttendee?: (credentials: any) => void
  questions: QuestionQueueItemDto[]
}

export default (props: QuestionQueueProps) => {
  const { onVerifiedAttendee } = props
  const [auth] = useAuthState()

  const liveQuestions = props.questions ? props.questions.filter((q: any) => q.status === 'ACCEPTED') : []
  const verifiedQuestions = props.questions
    ? props.questions.filter((q: any) => q.status === 'REQUESTED_VERIFICATION' || q.status === 'VERIFIED')
    : []
  const queuedQuestions = props.questions.filter((q: any) => q.status === 'CREATED')

  const hasLiveQuestions = liveQuestions.length > 0
  const hasVerifiedQuestions = verifiedQuestions.length > 0
  const hasQueuedQuestions = verifiedQuestions.length + queuedQuestions.length > 0
  const dequeueTargetDoc = liveQuestions[0]
  const enqueueTargetDoc = verifiedQuestions[0]

  /** ------------------------------------ **
   Event Handlers
   ** ------------------------------------ **/
  const onCancelAllQuestions = async () => {
    try {
      await cancelAllQuestions(auth.roomId, [...verifiedQuestions, ...queuedQuestions])
    } catch (e) {
      console.error(e)
    }
  }

  const nextQuestion = async (question?: any) => {
    if (liveQuestions.length > 0 && dequeueTargetDoc) {
      const q = dequeueTargetDoc as any
      await updateQuestion(q.docId, auth.roomId, {
        status: 'DELETED',
        roomCredentials: null,
      })
    }

    if (question || (verifiedQuestions?.length && enqueueTargetDoc)) {
      // Accept question (last queued or explicitly from params)
      const q = question || (enqueueTargetDoc as any)

      await generateTemporaryAttendeeRoomCredentials({
        roomId: auth.roomId,
        roomKey: auth.roomKey,
        participantKey: auth.participantKey,
        questionId: q.docId,
        attendeeParticipantKey: q.userId,
      })
    }
  }

  return (
    <div className={Style.container}>
      {hasLiveQuestions ? (
        <>
          <div className={Style.queueSection}>
            <div className={Style.queueSectionTitle}>Live</div>
            <div className={Style.queueSectionBody}>
              {liveQuestions.map((q: any) => (
                <QuestionQueueItem {...q} />
              ))}
            </div>
          </div>
          {hasVerifiedQuestions && hasLiveQuestions && (
            <Button className={Style.nextBtnMain} onClick={() => nextQuestion(null)}>
              Next
              <i className="ri-vidicon-line" />
            </Button>
          )}
        </>
      ) : null}
      {hasQueuedQuestions && (
        <div className={Style.queueSection}>
          <div className={Style.queueSectionTitle}>
            <span>Queue</span>
            <span className={Style.queueSectionCancelAll}>
              <PopConfirm title={'Are you sure you want to cancel all questions?'} onConfirm={onCancelAllQuestions}>
                <span>Cancel all</span>
              </PopConfirm>
            </span>
          </div>
          <div className={Style.queueSectionBody}>
            {verifiedQuestions.map((q: any) => (
              <QuestionQueueItem onNextQuestion={nextQuestion} {...q} acceptedQuestions={liveQuestions} />
            ))}
            {queuedQuestions.map((q: any) => (
              <QuestionQueueItem {...q} acceptedQuestions={liveQuestions} onVerifiedAttendee={onVerifiedAttendee} />
            ))}
          </div>
        </div>
      )}
      {!hasLiveQuestions && !hasQueuedQuestions && (
        <div className={Style.queueEmpty}>
          <i className="ri-question-answer-line" />
          <h3>
            No question has <br />
            been asked so far
          </h3>
        </div>
      )}
    </div>
  )
}
