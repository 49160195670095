import React, { useEffect, useState } from 'react'
import Components from '@cloudmeet/web-components'

import * as Style from './style'
import * as Routes from '../common/config/routes'
import VideoPlayer from './VideoPlayer'
import AskQuestion from './AskQuestion'
import AskQuestionInformation from './AskQuestionInformation'
import VerifyAttendee from '../verifyAttendee'
import AskQuestionStatusBar from './AskQuestionStatusBar'
import { ASK_QUESTION_FORM_STEP, ASK_QUESTION_INFORMATION_STEP } from './constants'
import Sidebar from '../sidebar'
import { Divider } from 'antd'
import useQueryRequest from '../common/hooks/useQueryRequest'

import {
  getDetailsForParticipant,
  GetDetailsForParticipantResponse,
  getSubscriberToken,
  GetSubscriberTokenResponse,
  joinPresentation,
} from '@cloudmeet/web-core/rooms/api'
import RoomLoader from '../common/components/RoomLoader'
import { getUserLastQuestion } from '@cloudmeet/web-core/questions/getUserLastQuestion'
import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'
import { listenBroadcasting } from '@cloudmeet/web-core/rooms/helpers'

import updateQuestion from '@cloudmeet/web-core/questions/updateQuestion'
import { Role } from '@cloudmeet/web-core/tenants/models'
import listenForRoomChanges from '@cloudmeet/web-core/rooms/listenForRoomChanges'
import listenForQuestionChanges from '@cloudmeet/web-core/questions/listenForQuestionChanges'
import useAuthState from '../common/hooks/useAuthState'
import listenForParticipantChanges from '@cloudmeet/web-core/rooms/listenForParticipantChanges'
import sendRemoveParticipantBeacon from '../common/helpers/sendRemoveParticipantBeacon'

const { PopConfirm, Button, Modal } = Components.UI

export default () => {
  /** ------------------------------------ **
     Hooks declaration
     ** ------------------------------------ **/
  const [auth, setAuth] = useAuthState()

  const { sendQueryRequest: sendGetDetailsForParticipant } = useQueryRequest()
  const { sendQueryRequest: sendGetSubscriberTokenResponse } = useQueryRequest()

  const [verifyAttendeeRoomDetails, setVerifyAttendeeRoomDetails] = useState<{
    name: string
    alternativeName: string
    jwt: string
    loadingMessage?: string
  } | null>(null)

  const [mediaStream, setMediaStream] = useState(null)
  const [askQuestionWizard, setAskQuestionWizard] = useState({
    nextStep: '',
  })
  const [lastQuestionId, setLastQuestionId] = useSharedState<string | null>(SharedStateKeys.LastQuestionId, null)
  const [shouldPlayStreaming, setShouldPlayStreaming] = useState(true)
  const [showAddQuestion, setShowAddQuestion] = useState(true)
  const [areRoomDataUpdated, setAreRoomDataUpdated] = useState<boolean>(false)
  /** ------------------------------------ **
     Event Handlers
     ** ------------------------------------ **/
  const onParticipantJoined = async (participantId: string) => {
    if (lastQuestionId) {
      await updateQuestion(lastQuestionId, auth.roomId, {
        participantJaasId: participantId,
      })
    }
  }

  /** ------------------------------------ **
     Effects
     ** ------------------------------------ **/
  const updateMediaStream = async () => {
    try {
      const subscriberTokenResponse = await sendGetSubscriberTokenResponse<GetSubscriberTokenResponse>(() =>
        getSubscriberToken({
          roomId: auth.roomId,
          roomKey: auth.roomKey,
          participantKey: auth.participantKey,
        }),
      )

      const peerConnectionStream = await listenBroadcasting({
        streamName: auth.roomId,
        accountId: 'vxUcKs', //TODO: take from api
        subscriberToken: subscriberTokenResponse?.token ?? '',
      })

      setMediaStream(peerConnectionStream)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    ;(async () => {
      let isMediaStreamUpdateTriggered = false

      listenForRoomChanges(auth.roomId, async (data: any) => {
        if (data.presentationEnded) {
          window.location.href = Routes.PRESENTATION_ENDED
          return
        }

        setAuth({
          ...auth,
          presentationStarted: data.presentationStarted,
          noteTimerStartsAt: data.noteTimerStartsAt,
        })

        setAreRoomDataUpdated(true)

        if (data.presentationStarted && !isMediaStreamUpdateTriggered) {
          await updateMediaStream()

          await joinPresentation({
            roomId: auth.roomId,
            roomKey: auth.roomKey,
            participantKey: auth.participantKey,
            conferenceId: auth.participantKey,
            participantName: auth.participantName,
          })

          isMediaStreamUpdateTriggered = true
        }
      })

      listenForParticipantChanges(auth.roomId, auth.participantKey, (data: any) => {
        if (data.kickedOut) {
          window.location.href = Routes.NOT_AUTHORIZED_URL
        }
      })

      const userLastQuestion = await getUserLastQuestion(auth.roomId, auth.participantKey)
      if (userLastQuestion) {
        setLastQuestionId(userLastQuestion.docId)
      }
    })()
  }, [])

  useEffect(() => {
    if (!lastQuestionId) return

    listenForQuestionChanges(auth.roomId, lastQuestionId, (data: any) => {
      if (data?.status === 'REQUESTED_VERIFICATION' && data?.roomCredentials) {
        setShouldPlayStreaming(false)
        setShowAddQuestion(false)
        setVerifyAttendeeRoomDetails({
          name: data.roomCredentials.roomSubject,
          alternativeName: data.roomCredentials.roomName,
          jwt: data.roomCredentials.jwt,
        })
      } else if (data?.status === 'VERIFIED' || data?.status === 'DELETED') {
        setVerifyAttendeeRoomDetails(null)
        setShouldPlayStreaming(true)
        setShowAddQuestion(false)
        if (data?.status === 'DELETED') {
          setShowAddQuestion(true)
        }
      } else if (data?.status === 'ACCEPTED' && data?.roomCredentials) {
        setShouldPlayStreaming(false)
        setShowAddQuestion(false)
        setVerifyAttendeeRoomDetails({
          name: data.roomCredentials.roomSubject,
          alternativeName: data.roomCredentials.roomName,
          jwt: data.roomCredentials.jwt,
          loadingMessage: 'Going live...',
        })
      } else if (data?.status === 'CREATED') {
        setShowAddQuestion(false)
      } else if (data?.status === 'ACCEPTED') {
        setShowAddQuestion(false)
      }
    })
  }, [lastQuestionId])

  useEffect(() => {
    window.addEventListener(
      'beforeunload',
      (event) => {
        sendRemoveParticipantBeacon({
          roomId: auth.roomId,
          roomKey: auth.roomKey,
          participantKey: auth.participantKey,
        })
      },
      false,
    )
  }, [])

  return (
    <div>
      <div className={Style.container}>
        {mediaStream && (
          <div className={Style.viewerContainer}>
            <div className={Style.experienceArea}>
              <VideoPlayer
                src={mediaStream}
                shouldPlayStreaming={shouldPlayStreaming}
                className={lastQuestionId ? 'video-player-with-status-bar' : 'video-player'}
              />
              {lastQuestionId && <AskQuestionStatusBar roomId={auth.roomId} />}
              <>
                <div className={Style.askQuestionForm}>
                  <AskQuestion
                    visible={askQuestionWizard.nextStep === ASK_QUESTION_FORM_STEP}
                    notifyVisibleChanges={(childProp: any) =>
                      setAskQuestionWizard({
                        nextStep: childProp,
                      })
                    }
                  />
                </div>
                {askQuestionWizard.nextStep === ASK_QUESTION_INFORMATION_STEP && (
                  <div className={Style.askQuestionForm}>
                    <AskQuestionInformation roomId={auth.roomId} />
                  </div>
                )}
              </>

              {showAddQuestion && (
                <Button
                  className={Style.askQuestionButton}
                  label={'Ask a question'}
                  type="normal"
                  icon={<i className="ri-chat-1-line" />}
                  onClick={() =>
                    setAskQuestionWizard({
                      nextStep: ASK_QUESTION_FORM_STEP,
                    })
                  }
                />
              )}

              {auth.requiresRecording && (
                <div className={Style.recordingIndicator}>
                  <i className="ri-record-circle-line" />
                  Recording
                </div>
              )}

              <PopConfirm
                title={'Leave meeting?'}
                onConfirm={async () => {
                  if (lastQuestionId) {
                    await updateQuestion(lastQuestionId, auth.roomId, {
                      status: 'DELETED',
                    })
                  }
                  window.location.href = Routes.MEETING_LEFT_URL
                }}
              >
                <Button
                  className={lastQuestionId ? Style.endPresentationButtonWithStatus : Style.endPresentationButton}
                  label={'Leave'}
                  type="danger"
                  onClick={() => {}}
                />
              </PopConfirm>
            </div>

            {<Sidebar />}
          </div>
        )}

        {!areRoomDataUpdated && <RoomLoader title={'Connecting...'} />}

        {areRoomDataUpdated && !auth.presentationStarted && <RoomLoader title={'Presentation has not started yet.'} />}
      </div>

      <Modal
        wrapClassName={Style.modalContent}
        title={verifyAttendeeRoomDetails?.name}
        visible={!!verifyAttendeeRoomDetails}
      >
        {verifyAttendeeRoomDetails && (
          <VerifyAttendee
            role={Role.Attendee}
            onParticipantJoined={(participantId) => onParticipantJoined(participantId)}
            roomSubject={verifyAttendeeRoomDetails.name}
            roomName={verifyAttendeeRoomDetails.alternativeName}
            jwt={verifyAttendeeRoomDetails.jwt}
            key={verifyAttendeeRoomDetails.alternativeName}
            loadingMessage={verifyAttendeeRoomDetails.loadingMessage}
            displayName={auth.participantName}
          />
        )}
        <Divider />
        <div className={Style.modalFooterDeviceStatusInfo}>
          <i className="ri-information-line ri-lg" />
          <span>Your microphone has to be ON</span>
        </div>
      </Modal>
    </div>
  )
}
