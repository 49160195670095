import React, { useState } from 'react'
import { ParticipantDto } from '@cloudmeet/web-core/rooms/models'
import { Role } from '@cloudmeet/web-core/tenants/models'
import Components from '@cloudmeet/web-components'
import * as Yup from 'yup'
import { Formik, FormikValues } from 'formik'
import * as Style from './style'
type ComponentPros = {
  participants: ParticipantDto[]
  onTotalRoomsChanged?: (totalRooms: number) => void
}

const { Input } = Components.UI

export default (props: ComponentPros) => {
  const totalPresenters = props.participants.filter((x) => x.role === Role.Presenter).length
  const totalAttendees = props.participants.filter((x) => x.role === Role.Attendee).length

  const onRoomNumberChanged = (newValue: string) => {
    const total = newValue ? parseInt(newValue, 10) : 0
    if (props.onTotalRoomsChanged) {
      props.onTotalRoomsChanged(total)
    }
  }

  return (
    <div>
      <h4 className={'text-center'}>
        You currently have <b>{totalPresenters} presenter(s)</b> and <b>{totalAttendees} attendee(s)</b>
      </h4>
      <h4 className={'text-center'}>Enter the number of breakout rooms you want to create</h4>
      <div className={Style.createBreakoutRoomsFormWrap}>
        <Formik
          enableReinitialize
          initialValues={{
            totalRooms: null,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(values: FormikValues) => {}}
        >
          {(form) => (
            <Input
              type={'number'}
              min={1}
              name={'totalRooms'}
              onChangeFunction={onRoomNumberChanged}
              placeholder={'0'}
              autoFocus={true}
              {...form}
            />
          )}
        </Formik>
      </div>
    </div>
  )
}
