import Components from '@cloudmeet/web-components'
import { Button, Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import { getQuestion } from '@cloudmeet/web-core/questions/getQuestion'
import useSharedState, { SharedStateKeys } from '../common/hooks/useSharedState'

const { Modal } = Components.UI

export default (props: any) => {
  const { roomId } = props
  const [lastQuestionId] = useSharedState<string | null>(SharedStateKeys.LastQuestionId, null)
  const [showModal, setShowModal] = useState(false)
  const [isTextQuestion, setIsTextQuestion] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (!lastQuestionId) return

      const result = await getQuestion(roomId, lastQuestionId)
      if (result) {
        setIsTextQuestion(result.type === 'TEXT' && result.status === 'CREATED')
        setShowModal(true)
      }
    })()
  }, [lastQuestionId])

  const onContinue = () => {
    setShowModal(false)
  }

  return (
    <>
      <Modal
        visible={showModal}
        title={isTextQuestion ? 'Information' : 'Ask question using video'}
        footer={[
          <Button key="submit" type="primary" onClick={onContinue}>
            Ok
          </Button>,
        ]}
        destroyOnClose={true}
      >
        <Empty
          image={<i className="ri-information-line ri-lg" />}
          imageStyle={{
            height: 28,
          }}
          description={
            isTextQuestion ? (
              <span>The moderator will ask your question to the panelists very soon</span>
            ) : (
              <span>
                The moderator will open a call with you first and then you will be able to ask the question by using
                Audio/Video to the panelists
              </span>
            )
          }
        />
      </Modal>
    </>
  )
}
