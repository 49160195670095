import React, { useEffect, useRef } from 'react'
import Plyr from 'plyr'

type VideoPlayerProps = {
  src: MediaStream | null
  shouldPlayStreaming: boolean
  className?: string
}

const usePlayer = (props: VideoPlayerProps) => {
  const { src, shouldPlayStreaming } = props
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    // @ts-ignore
    const player = new Plyr(videoRef.current as any, {
      autoplay: true,
      controls: ['volume'],
      muted: false,
      disableContextMenu: true,
      hideControls: false,
      clickToPlay: false,
      // ratio: '16:9',
    })

    return () => {
      if (player !== null) {
        // @ts-ignore
        player.destroy()
      }
    }
  }, [])

  useEffect(() => {
    ;(videoRef.current as any).srcObject = src
  }, [src])

  useEffect(() => {
    const videoStream = videoRef.current as any
    if (!shouldPlayStreaming) {
      videoStream.plyr.pause()
    } else if (shouldPlayStreaming && videoStream.paused) {
      videoStream.plyr.play()
    }
  }, [shouldPlayStreaming])

  return videoRef
}

const VideoPlayer = (props: VideoPlayerProps) => {
  const { src, shouldPlayStreaming, className } = props
  const playerRef = usePlayer({ src, shouldPlayStreaming })

  return (
    <div data-vjs-player className={className}>
      <video ref={playerRef} playsInline autoPlay />
    </div>
  )
}

export default VideoPlayer
