import React, { useCallback, useEffect, useRef, useState } from 'react'
import * as Style from './style'
import { Role } from '@cloudmeet/web-core/tenants/models'
import RoomLoader from '../common/components/RoomLoader'

import useMediaState from '../common/hooks/useMediaState'

export type Props = {
  roomName: string
  roomSubject: string
  jwt: string
  displayName: string
  onParticipantJoined?: (participantId: string) => void
  role: Role
  loadingMessage?: string
}

export default (props: Props) => {
  const apiRef = useRef(null)

  const [media, setMedia] = useMediaState()
  const [showLoader, setShowLoader] = useState<boolean>(true)

  const getJitsiMeetInstance = (): any => {
    return apiRef.current
  }

  const updateAudioVideoSettings = async () => {
    const jitsiMeet = getJitsiMeetInstance()

    const devices = await jitsiMeet.getCurrentDevices()
    const videoMuted = await jitsiMeet.isVideoMuted()

    setMedia({
      ...media,
      videoDevice: devices?.videoInput?.label,
      audioInputDevice: devices?.audioInput?.label,
      audioOutputDevice: devices?.audioOutput?.label,
      videoEnabled: !videoMuted,
    })
  }

  const initJitsi = (parentNode: any) => {
    //@ts-ignore
    const jitsiMeet = new JitsiMeetExternalAPI('8x8.vc', {
      roomName: props.roomName,
      parentNode: parentNode,
      jwt: props.jwt,
      configOverwrite: {
        prejoinPageEnabled: false,
        subject: props.roomSubject,
        hideConferenceTimer: true,
        hideParticipantsStats: true,
        toolbarButtons: ['microphone', 'camera'],
        startWithAudioMuted: false,
        startWithVideoMuted: !media.videoEnabled,
        readOnlyName: true,
      },
      interfaceConfigOverwrite: {
        TOOLBAR_ALWAYS_VISIBLE: false,
        TOOLBAR_BUTTONS: ['microphone', 'camera'],
        DEFAULT_BACKGROUND: '#1A202C',
        DISABLE_VIDEO_BACKGROUND: true,
      },
      devices: {
        audioInput: media.audioInputDevice,
        audioOutput: media.audioOutputDevice,
        videoInput: media.videoDevice,
      },
    })

    jitsiMeet.on('videoConferenceJoined', async (data: any) => {
      setShowLoader(false)
      if (props.onParticipantJoined) {
        props.onParticipantJoined(data.id)
      }
    })

    jitsiMeet.on('audioAvailabilityChanged', async () => {
      await updateAudioVideoSettings()
    })

    jitsiMeet.on('videoAvailabilityChanged', async () => {
      await updateAudioVideoSettings()
    })

    apiRef.current = jitsiMeet
  }

  const jitsiRefCallBack = useCallback((node: any) => {
    if (node !== null) {
      initJitsi(node)
    }
  }, [])

  return (
    <div>
      {showLoader && <RoomLoader style={{ height: '100%' }} title={props.loadingMessage ?? 'Connecting...'} />}
      <div className={Style.iframeContainer} ref={jitsiRefCallBack} />
    </div>
  )
}
